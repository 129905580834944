(function () {
  app.controller('CartsCtrl', [
    "$scope", '$timeout',
    function ($scope, $timeout) {
      $scope.shipping = {
        shipping_first_name: '',
        shipping_last_name: '',
        shipping_address: '',
        shipping_address_optional: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip_code: '',
        shipping_country: 'US',
        shipping_phone_number: ''
      }
      $scope.error = '';
      $scope.data_checkout = {};

      $scope.init = function () {};

      $scope.changeTab = function (tab) {
        $scope.current_tab = tab;
        switch (tab) {
          case 'order_summary':
            $scope.title = 'Order Summary';
            break;
        
          case 'checkout':
            $scope.title = 'Checkout';
            break;

          case 'proceed_to_payment':
            $scope.title = 'Checkout';
            break;
        }
      }

      $scope.qtyDecrement = function () {
        $scope.qty -= 1;
        if ($scope.qty <= 0) {
          $scope.qty = 1;
        }
      }

      $scope.qtyIncrement = function () {
        if ($scope.qty + 1 > $scope.max_qty) {
          toastr.error(`The quantity remaining is ${$scope.max_qty}.`)
        } else {
          $scope.qty += 1;
        }
      }

      $scope.validateShippingForm = function() {
        // Loop through each key in shipping
        for (const key in $scope.shipping) {
          if (key !== 'shipping_address_optional' && !$scope.shipping[key]) {
            $scope.error = `Please fill out the required fields.`;
            return false; // Stop submission
          }
          if (key === 'shipping_phone_number' && $scope.shipping.shipping_phone_number && isNaN($scope.shipping.shipping_phone_number)) {
            $scope.error = 'Phone number must be a valid number.';
            return false;
          }
          if (key === 'shipping_zip_code') {
            const zipCode = $scope.shipping.shipping_zip_code;
            if (!/^\d[\d\s\-]*$/.test(zipCode)) {  // RegEx checks for numeric, space, or hyphen
              $scope.error = 'Zip code must not contain letters.';
              return false;
            }
          }
        }
        $scope.error = '';
        return true;
      }

      $scope.checkout = function () {
        if ($scope.validateShippingForm()) {
          $scope.shipping.qty = $scope.qty;
          $.ajax({
            beforeSend: function(){
              // $(".loading").hide();
              // $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
              // $(".loading").attr('style', 'z-index: -1 !important');
            },
            url: $scope.checkout_url,
            dataType: "json",
            cache: false,
            type: "POST",
            data: { order: $scope.shipping, box: $scope.box_slug, store_slug: $scope.store_slug, amount: $scope.price },
            success: function (rs) {
              $timeout(function () {
                if (rs.succeed) {
                  $scope.data_checkout = rs.data;
                  $scope.changeTab('proceed_to_payment');
                  $timeout(function () {
                    $scope.getStripe();
                  });
                } else {
                  toastr.error("Whoa! An error occurred! Please try again");
                }
              });
            },
          })
        }
      }

      $scope.getStripe = function () {
        $scope.stripe = Stripe($scope.stripe_publishable_key)
        var appearance = {
              theme: 'night',
              variables: { 
                'fontFamily': 'Inter, Arial, sans-serif',
                'borderRadius': '10px',
                'colorBackground': '#191919',
                'colorText': '#FFFFFF80',
                'p-colorBackgroundDeemphasize05': '#FFFFFF',
                'colorBackgroundDeemphasize05': '#FFFFFF',
              },
              rules: {
                '.Input': {
                  border: '1px solid rgba(255, 255, 255, 0.2)',
                  color: '#FFF'
                }
              }
            },
            options = {
              layout: {
                type: 'tabs',
                defaultCollapsed: false,
              }
            },
            clientSecret = $scope.data_checkout.client_secret;
        $scope.elements = $scope.stripe.elements({ clientSecret, appearance }),
        $scope.paymentElement = $scope.elements.create('payment', options);
        $scope.paymentElement.mount('#payment-element');
      }

      $scope.submitOrder = async function () {
        $scope.showLoading();
        let elements = $scope.elements;
        var { error } = await $scope.stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: $scope.return_url,
          }
        });

        if (error) {
          const messageContainer = document.getElementById('error-message');
          messageContainer.textContent = error.message;
          $scope.hideLoading();
        }
      }

      $scope.showLoading = function () {
        $(".loading").show();
        $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
        $(".loading").attr('style', 'z-index: 9999 !important');
      }

      $scope.hideLoading = function () {
        $(".loading").hide();
        $(".loading").attr('style', 'background: rgba(0, 0, 0, 0)');
        $(".loading").attr('style', 'z-index: -1 !important');
      }
    }
  ]);

}).call(this);